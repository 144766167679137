import { CCol, CInput, CRow } from '@coreui/react';
import React, { useState } from 'react';
import Button from '../../../components/Button/Button';
import DataCard from '../../../components/DataCard/DataCard';
import DataRow from '../../../components/DataRow/DataRow';
import { useMutation } from 'react-query';
import CustomersService from '../../../services/CustomersService';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loader from '../../../components/Loader/Loader';
import { toast } from 'react-toastify';

const ClientBox = function ({
  city,
  customer_type_name,
  customer_type_pl,
  email,
  name,
  phone,
  surname,
  company_name,
  postal_code,
  street,
  flat_number,
  nip,
  enableEdit = false,
  refetch = () => {},
}) {
  const { id: customerId } = useParams();
  const { register, handleSubmit } = useForm();

  const [isEditable, setIsEditable] = useState(false);

  const [editClient, { isLoading }] = useMutation(
    CustomersService.updateCustomer(customerId),
    {
      onSuccess: () => {
        refetch();
        setIsEditable(false);
        toast.success('Zmiany zostały zapisane.');
      },
      onError: () => {
        toast.error('Ten numer jest nieprawidłowy.');
      },
    }
  );

  const onSubmit = (values) => {
    editClient(values);
  };

  const toggleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  return (
    <>
      <Loader show={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DataCard
          title="Dane klienta"
          HeaderButtons={
            enableEdit && (
              <>
                {isEditable ? (
                  <div className="d-flex">
                    <Button className="mr-2" type="submit">
                      Zapisz
                    </Button>
                    <Button onClick={toggleEdit}>Anuluj</Button>
                  </div>
                ) : (
                  <Button onClick={toggleEdit}>Edytuj</Button>
                )}
              </>
            )
          }
        >
          <CRow>
            {nip && (
              <CCol sm="12" xl="12">
                <DataRow fullHeight label="Nip Wnioskodawcy" value={nip} />
              </CCol>
            )}
            <CCol sm="12" xl="12">
              <DataRow
                fullHeight
                label="Forma prawna"
                value={customer_type_pl}
              />
            </CCol>
            {(customer_type_name === 'LEGAL_PERSON' ||
              customer_type_name === 'INDIVIDUAL_ENTREPRENEUR') && (
              <CCol sm="12" xl="12">
                <DataRow fullHeight label="Nazwa" value={company_name} />
              </CCol>
            )}
            <CCol sm="12" xl="12">
              <DataRow fullHeight label="Imię" value={name} />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow fullHeight label="Nazwisko" value={surname} />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow
                fullHeight
                label="Telefon"
                value={
                  isEditable ? (
                    <CInput
                      defaultValue={phone}
                      name="phone"
                      innerRef={register}
                    />
                  ) : (
                    phone
                  )
                }
              />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow fullHeight label="Email" value={email} />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow fullHeight label="Miasto" value={city} />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow fullHeight label="Kod pocztowy" value={postal_code} />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow fullHeight label="Ulica" value={street} />
            </CCol>
            <CCol sm="12" xl="12">
              <DataRow
                fullHeight
                label="Numer Mieszkania"
                value={flat_number}
              />
            </CCol>
          </CRow>
        </DataCard>
      </form>
    </>
  );
};

export default ClientBox;
