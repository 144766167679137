import React from 'react';
import { ROLES } from './roles';
import Matrix from './views/Matrix/Matrix';
import PartnerMatrix from './views/Matrix/PartnerMatrix';
import SelectShop from './views/Matrix/SelectShop';
import ProductsDescriptions from './views/ProductsDescriptions/ProductsDescriptions';
import Customer from './views/Customer/Customer';

const Redirector = React.lazy(() => import('./views/Redirector/Redirector'));
const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const Application = React.lazy(() => import('./views/Application/Application'));
const ApplicationCompany = React.lazy(() =>
  import('./views/Application/ApplicationCompany')
);
const ApplicationEntrepreneur = React.lazy(() =>
  import('./views/Application/ApplicationEntrepreneur')
);
const ApplicationsActive = React.lazy(() =>
  import('./views/Applications/ApplicationsActive/ApplicationsActive')
);
const ApplicationsExamined = React.lazy(() =>
  import('./views/Applications/ApplicationsExamined/ApplicationsExamined')
);
const ApplicationsWrong = React.lazy(() =>
  import('./views/Applications/ApplicationsWrong/ApplicationsWrong')
);
const ApplicationsArchived = React.lazy(() =>
  import('./views/Applications/ApplicationsArchived/ApplicationsArchived')
);
const MainProducts = React.lazy(() =>
  import('./views/Products/Main/MainProducts')
);
const AddMainProduct = React.lazy(() =>
  import('./views/Products/Main/AddMainProduct')
);
const AddAdditionalProduct = React.lazy(() =>
  import('./views/Products/Additional/AddAdditionalProduct')
);
const AddAccessories = React.lazy(() =>
  import('./views/Products/Accessories/AddAccessories')
);
const AddServices = React.lazy(() =>
  import('./views/Products/Services/AddServices')
);
const AdditionalProducts = React.lazy(() =>
  import('./views/Products/Additional/AdditionalProducts')
);
const AddBrand = React.lazy(() => import('./views/Brand/AddBrand'));
const UpdateBrand = React.lazy(() => import('./views/Brand/UpdateBrand'));
const Accessories = React.lazy(() =>
  import('./views/Products/Accessories/Accessories')
);
const Services = React.lazy(() => import('./views/Products/Services/Services'));
const Reservations = React.lazy(() =>
  import('./views/Reservations/Reservations')
);
const Agreement = React.lazy(() => import('./views/Agreement/Agreement'));
const Agreements = React.lazy(() => import('./views/Agreements/Agreements'));

const Shops = React.lazy(() => import('./views/Shops/Shops'));
const Shop = React.lazy(() => import('./views/Shop/Shop'));
const NewPickupPoint = React.lazy(() => import('./views/Shop/NewPickupPoint'));
const NewPickupPointAdmin = React.lazy(() =>
  import('./views/Shop/NewPickupPointAdmin')
);

const Assets = React.lazy(() => import('./views/Assets/Assets'));
const Asset = React.lazy(() => import('./views/Asset/Asset'));

const ServicesInAssetsAndServices = React.lazy(() =>
  import('./views/Services/Services')
);

const Product = React.lazy(() => import('./views/Product/Product'));
const Orders = React.lazy(() => import('./views/Orders/Orders'));
const OrdersArchived = React.lazy(() =>
  import('./views/Orders/OrdersArchived')
);
const Order = React.lazy(() => import('./views/Order/Order'));

const Customers = React.lazy(() => import('./views/Customers/Customers'));
const Users = React.lazy(() => import('./views/Users/Users'));
const NewUser = React.lazy(() => import('./views/Users/NewUser'));

const Repurchase = React.lazy(() => import('./views/Repurchase/Repurchase'));
const RepurchaseSingle = React.lazy(() =>
  import('./views/RepurchaseSingle/RepurchaseSingle')
);
const ReturnsSingle = React.lazy(() =>
    import('./views/RepurchaseSingle/ReturnsSingle')
);
const Returns = React.lazy(() =>
  import('./views/Repurchase/Returns')
);

const Settings = React.lazy(() => import('./views/Settings/Settings'));
const CronIntegrations = React.lazy(() => import('./views/Settings/CronIntegrations'));
const Ecommerce = React.lazy(() => import('./views/Ecommerce/Ecommerce'));
const Payments = React.lazy(() => import('./views/Payments/Payments'));
const Categories = React.lazy(() => import('./views/Categories/Categories'));
const AddCategory = React.lazy(() => import('./views/Categories/AddCategory'));
const EditCategory = React.lazy(() =>
  import('./views/Categories/EditCategory')
);

const AddReturnProtocol = React.lazy(() =>
  import('./views/ReturnProtocol/AddReturnProtocol')
);
const UpdateReturnProtocol = React.lazy(() =>
  import('./views/ReturnProtocol/UpdateReturnProtocol')
);

const AcceptanceList = React.lazy(() =>
  import('./views/Products/AcceptanceList/AcceptanceList')
);

const Brands = React.lazy(() => import('./views/Brands/Brands'));

const Search = React.lazy(() => import('./views/Search/Search'));

const RecommendedProducts = React.lazy(() =>
  import('./views/RecommendedProducts/RecommendedProducts')
);

const AddManual = React.lazy(() => import('./views/AddManual/AddManual'));

const Manual = React.lazy(() => import('./views/Manual/Manual'));

const SearchProducts = React.lazy(() =>
  import('./views/SearchProducts/SearchProducts')
);

const SearchTransactions = React.lazy(() =>
  import('./views/SearchTransactions/SearchTransactions')
);

const Manuals = React.lazy(() => import('./views/Manuals/Manuals'));

const Vouchers = React.lazy(() => import('./views/Vouchers/Vouchers'));
const Voucher = React.lazy(() => import('./views/Voucher/Voucher'));

const CustomersUnverified = React.lazy(() =>
  import('./views/CustomersUnverified/CustomersUnverified')
);

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/', exact: true, name: 'Panel Administracyjny' },

  // {
  //   path: "/applications/:type/:customerType/:id",
  //   exact: true,
  //   name: "Wniosek",
  //   component: Application,
  //   scopes: [SCOPES.ADMIN],
  // },
  {
    path: '/',
    exact: true,
    name: 'Dashboard',
    component: Dashboard,
    scopes: ROLES.PARTNER.ALL().concat([ROLES.VIBE.ADMIN]),
  },
  {
    path: '/redirector',
    exact: true,
    name: 'Redirector',
    component: Redirector,
    scopes: ROLES.ALL(),
  },
  {
    path: '/applications/:type/personal/:id',
    exact: true,
    name: 'Wniosek',
    component: Application,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/applications/:type/company/:id',
    exact: true,
    name: 'Wniosek',
    component: ApplicationCompany,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/applications/:type/entrepreneur/:id',
    exact: true,
    name: 'Wniosek',
    component: ApplicationEntrepreneur,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },

  {
    path: '/applications/active',
    exact: true,
    name: 'Wnioski aktywne',
    component: ApplicationsActive,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/applications/examined',
    exact: true,
    name: 'Wnioski rozpatrzone',
    component: ApplicationsExamined,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/applications/error',
    exact: true,
    name: 'Wnioski z błędem BIK',
    component: ApplicationsWrong,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/applications/archived',
    exact: true,
    name: 'Wnioski zarchiwizowane',
    component: ApplicationsArchived,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/products/main/add',
    exact: true,
    name: 'Dodaj Produkt główny',
    component: AddMainProduct,
    scopes: ROLES.ALL(),
  },
  {
    path: '/products/additional/add',
    exact: true,
    name: 'Dodaj Produkt dodatkowy',
    component: AddAdditionalProduct,
    scopes: ROLES.ALL(),
  },
  {
    path: '/products/accessories/add',
    exact: true,
    name: 'Dodaj Akcesoria',
    component: AddAccessories,
    scopes: ROLES.ALL(),
  },
  {
    path: '/products/services/add',
    exact: true,
    name: 'Dodaj usługę',
    component: AddServices,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/products/:type/:id',
    exact: true,
    name: 'Produkt',
    component: Product,
    scopes: ROLES.ALL(),
  },
  {
    path: '/products/main',
    exact: true,
    name: 'Produkty główne',
    component: MainProducts,
    scopes: ROLES.ALL(),
  },
  {
    path: '/products/additional',
    exact: true,
    name: 'Produkty Dodatkowe',
    component: AdditionalProducts,
    scopes: ROLES.ALL(),
  },
  {
    path: '/products/accessories',
    exact: true,
    name: 'Akcesoria',
    component: Accessories,
    scopes: ROLES.ALL(),
  },
  {
    path: '/products/services',
    exact: true,
    name: 'Usługi',
    component: Services,
    scopes: ROLES.ALL(),
  },

  {
    path: '/product-acceptance-list/:symbol',
    exact: true,
    name: 'Import listy',
    component: AcceptanceList,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/product-acceptance-list',
    name: 'Wybierz Partnera',
    component: SelectShop,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/orders/archived',
    name: 'Zamówienia Archiwizowane',
    component: OrdersArchived,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/orders/:id',
    name: 'Zamówienie',
    component: Order,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    path: '/orders',
    name: 'Zamówienia',
    component: Orders,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    path: '/customers/:customerType/:id',
    name: 'Klient',
    component: Customer,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },

  {
    path: '/customers',
    name: 'Klienci',
    component: Customers,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/customers-unverified',
    name: 'Klienci niezweryfikowani',
    component: CustomersUnverified,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/reservations',
    name: 'Rezerwacje',
    component: Reservations,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    path: '/repurchase/:id',
    name: 'Odkupy',
    component: RepurchaseSingle,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    path: '/repurchase',
    name: 'Odkupy',
    component: Repurchase,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    path: '/returns/:id',
    name: 'Odkupy',
    component: ReturnsSingle,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    path: '/returns',
    name: 'Zwroty',
    component: Returns,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    path: '/assets&services/assets/:id',
    name: 'Aktywa',
    component: Asset,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/assets&services/assets',
    name: 'Aktywa',
    component: Assets,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/assets&services/services',
    name: 'Aktywa',
    component: ServicesInAssetsAndServices,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/agreements/:id',
    name: 'Umowa',
    component: Agreement,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/agreements',
    name: 'Umowy',
    component: Agreements,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
      ROLES.VIBE.MANAGER,
    ],
  },
  {
    path: '/shops/:id',
    exact: true,
    name: 'Partnerzy',
    component: Shop,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/manuals/new',
    exact: true,
    name: 'Dodaj Manual',
    component: AddManual,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/manuals/:manualId',
    exact: true,
    name: 'Nowy Manual',
    component: Manual,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/shops/:id/manual/:manualId',
    exact: true,
    name: 'Nowy Manual',
    component: Manual,
    scopes: ROLES.VIBE.ALL(),
  },

  {
    path: '/partners',
    name: 'Partnerzy',
    component: Shops,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/manuals',
    name: 'Manuals',
    component: Manuals,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/partner-data/add-pickup-point',
    name: 'Dodawanie Punktu odbioru',
    component: NewPickupPoint,
    scopes: ROLES.PARTNER.ALL(),
  },
  {
    path: '/partner-data/add-pickup-point/:shop_id',
    name: 'Dodawanie Punktu odbioru przez Admina',
    component: NewPickupPointAdmin,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/partner-data',
    name: 'Dane Partnera',
    component: Shop,
    scopes: ROLES.ALL(),
  },
  {
    path: '/users/new',
    name: 'Dodawanie pracownika',
    component: NewUser,
    scopes: ROLES.VIBE.ALL().concat([
      ROLES.PARTNER.PARTNER,
      ROLES.PARTNER.MANAGER,
    ]),
  },
  {
    path: '/users/:id/new',
    name: 'Dodawanie pracownika',
    component: NewUser,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/users',
    name: 'Pracownicy',
    component: Users,
    scopes: ROLES.VIBE.ALL().concat([
      ROLES.PARTNER.PARTNER,
      ROLES.PARTNER.MANAGER,
    ]),
  },
  {
    path: '/settings-crons',
    name: 'Crony',
    component: CronIntegrations,
    scopes: [ROLES.VIBE.ADMIN, ROLES.VIBE.MANAGER],
  },
  {
    path: '/settings',
    name: 'Ustawienia',
    component: Settings,
    scopes: [ROLES.VIBE.ADMIN, ROLES.VIBE.MANAGER],
  },

  {
    path: '/ecommerce',
    name: 'Ecommerce',
    component: Ecommerce,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.MANAGER,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.PRODUCT_MANAGER,
    ],
  },
  {
    path: '/payments',
    name: 'Płatności',
    component: Payments,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.MANAGER,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
    ],
  },
  {
    path: '/:shop/partnerMatrix/',
    name: 'Ustawienia produktów',
    component: PartnerMatrix,
    scopes: ROLES.PARTNER.ALL(),
  },
  {
    path: '/:shop/matrix/',
    name: 'Ustawienia produktów',
    component: Matrix,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/:shop/recommended-products/:mainProductId/type/:type',
    name: 'Ustawienia rekomendowanych produktów',
    component: RecommendedProducts,
    // scopes: [...PERMISSIONS.ADMIN_TRADER],
  },
  {
    path: '/matrix/',
    name: 'Ustawienia produktów',
    component: SelectShop,
    scopes: ROLES.ALL(),
  },
  {
    path: '/categories/',
    name: 'Kategorie',
    exact: true,
    component: Categories,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/categories/add',
    name: 'Dodaj kategorie',
    exact: true,
    component: AddCategory,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/category/:id',
    name: 'Edytuj kategorie',
    exact: true,
    component: EditCategory,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/brands/',
    name: 'Marki',
    component: Brands,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/brand/add',
    exact: true,
    name: 'Dodaj Markę',
    component: AddBrand,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/brand/:id',
    exact: true,
    name: 'Edytuj Markę',
    component: UpdateBrand,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/return-protocol/add/:order_id',
    exact: true,
    name: 'Dodaj Protokół Zwrotu',
    component: AddReturnProtocol,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    path: '/return-protocol/update/:order_id/:id',
    exact: true,
    name: 'Edytuj Protokół Zwrotu',
    component: UpdateReturnProtocol,
    scopes: ROLES.ALL().filter((role) => role !== ROLES.VIBE.PRODUCT_MANAGER),
  },
  {
    path: '/search/:query+',
    exact: true,
    name: 'Szukaj',
    component: Search,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.MANAGER,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
    ],
  },
  {
    path: '/search-products/:query+',
    exact: true,
    name: 'Szukaj',
    component: SearchProducts,
    scopes: ROLES.VIBE.ALL(),
  },
  {
    path: '/search-transactions/:query+',
    exact: true,
    name: 'Szukaj',
    component: SearchTransactions,
    scopes: [
      ROLES.VIBE.ADMIN,
      ROLES.VIBE.MANAGER,
      ROLES.VIBE.TRADER,
      ROLES.VIBE.ANALYST,
    ],
  },
  {
    path: '/vouchers',
    exact: true,
    name: 'Vouchery',
    component: Vouchers,
    scopes: ROLES.VIBE.ALL().filter(
      (role) => role !== ROLES.VIBE.PRODUCT_MANAGER
    ),
  },
  {
    path: '/vouchers/:id',
    exact: true,
    name: 'Voucher',
    component: Voucher,
    scopes: ROLES.VIBE.ALL().filter(
      (role) => role !== ROLES.VIBE.PRODUCT_MANAGER
    ),
  },
  {
    path: '/products-descriptions',
    exact: true,
    name: 'ProductsDescriptions',
    component: ProductsDescriptions,
    scopes: ROLES.VIBE.ALL(),
  },
];

export default routes;
