import { CBadge } from '@coreui/react';
import React from 'react';

export const TableColStatus = ({ status_symbol, status_name }) => {
  return (
    <td>
      {status_symbol === 'ACTIVE' && (
        <CBadge color="success">{status_name}</CBadge>
      )}
      {status_symbol === 'FINISHED_SETTLED' && (
        <CBadge color="dark">{status_name}</CBadge>
      )}
      {status_symbol === 'FINISHED_UNSETTLED' && (
        <CBadge color="dark">{status_name}</CBadge>
      )}
    </td>
  );
};
