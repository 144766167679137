import { CCard, CCardBody, CCardHeader, CCardTitle } from '@coreui/react';
import React from 'react';
import Button from '../../../components/Button/Button';
import { useMutation } from 'react-query';
import CustomersService from '../../../services/CustomersService';
import Loader from '../../../components/Loader/Loader';
import { toast } from 'react-toastify';

const VerificationBox = ({
  customerId,
  customerType,
  companyName,
  refetch,
}) => {
  // ---------------------------------------------------------------------
  // --------------------------- ACCEPT SERVICE --------------------------
  // ---------------------------------------------------------------------

  const [acceptVerification, { isLoading: isLoadingAcceptVerification }] =
    useMutation(CustomersService.acceptCustomerVerification(customerId), {
      onSuccess: () => {
        toast.success('Klienta został zweryfikowany pozytywnie.');
        refetch();
      },
      onError: (error) => {
        toast.error(
          error?.response?.data?.data || 'Ups... Coś poszło nie tak.'
        );
      },
    });

  // ---------------------------------------------------------------------
  // --------------------------- REJECT SERVICE --------------------------
  // ---------------------------------------------------------------------

  const [rejectVerification, { isLoading: isLoadingRejectVerification }] =
    useMutation(CustomersService.rejectCustomerVerification(customerId), {
      onSuccess: () => {
        toast.success('Klienta został zweryfikowany negatywnie.');
        refetch();
      },
      onError: (error) => {
        toast.error(
          error?.response?.data?.data || 'Ups... Coś poszło nie tak.'
        );
      },
    });

  // ---------------------------------------------------------------------
  // --------------------------- ACCEPT HANDLER --------------------------
  // ---------------------------------------------------------------------

  const handleAcceptVerification = () => {
    acceptVerification({
      c2: customerType,
      c1: companyName,
    });
  };

  // ---------------------------------------------------------------------
  // --------------------------- REJECT HANDLER --------------------------
  // ---------------------------------------------------------------------

  const handleRejectVerification = () => {
    rejectVerification({
      c2: customerType,
      c1: companyName,
    });
  };

  // ---------------------------------------------------------------------
  // --------------------------- STATUSES --------------------------------
  // ---------------------------------------------------------------------

  const isLoading = isLoadingAcceptVerification || isLoadingRejectVerification;

  //   #############################################################################
  //   #############################################################################
  //   #############################################################################

  return (
    <>
      {isLoading && <Loader show />}
      <CCard className={'bg-light-secondary text-black shadow-none'}>
        <CCardHeader>
          <CCardTitle>Klient czeka na weryfikację</CCardTitle>
        </CCardHeader>
        <CCardBody className={'d-flex gap-3'}>
          <Button
            onClick={handleAcceptVerification}
            className="w-100"
            variant=""
            color="success"
          >
            Zweryfikuj pozytywnie klienta
          </Button>
          <Button
            onClick={handleRejectVerification}
            className="w-100"
            variant=""
            color="danger"
          >
            Zweryfikuj negatywnie klienta
          </Button>
        </CCardBody>
      </CCard>
    </>
  );
};

export default VerificationBox;
