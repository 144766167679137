import React from 'react';
import { VERIFICATION_STATUS } from '../../variables';
import { CBadge } from '@coreui/react';

const statusColorMap = {
  [VERIFICATION_STATUS.ACCEPTED.SYMBOL]: 'success',
  [VERIFICATION_STATUS.BIK_ERROR.SYMBOL]: 'danger',
  [VERIFICATION_STATUS.CREATED.SYMBOL]: 'secondary',
  [VERIFICATION_STATUS.EMAIL_CONFIRMED.SYMBOL]: 'grey2',
  [VERIFICATION_STATUS.PENDING.SYMBOL]: 'primary',
  [VERIFICATION_STATUS.REJECTED.SYMBOL]: 'danger',
  [VERIFICATION_STATUS.STARTED.SYMBOL]: 'light-secondary',
};

const VerificationStatusBadge = ({ status, ...props }) => {
  const color = statusColorMap[status];
  const label = VERIFICATION_STATUS[status]?.NAME_PL;

  return (
    <CBadge color={color} {...props}>
      {label}
    </CBadge>
  );
};

export default VerificationStatusBadge;
