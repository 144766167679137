import { CCol, CRow } from '@coreui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import DataCard from '../../../components/DataCard/DataCard';
import Table from '../../../components/Table/Table';
import { TableColStatus } from './TableColStatus';

const AgreementsBox = function ({ agreements }) {
  return (
    <DataCard title="Umowy">
      <CRow>
        <CCol sm="12" xl="12" className="mt-3">
          <Table
            data={agreements}
            striped={false}
            noBorder={true}
            scopedSlots={{
              show_details: ({ entity_id }) => (
                <td className="py-2">
                  <Link to={`/agreements/${entity_id}`}>
                    <Button>Szczegóły</Button>
                  </Link>
                </td>
              ),
              status_symbol: TableColStatus,
            }}
            fields={[
              {
                key: 'agreement_number',
                label: 'Numer umowy',
              },
              {
                key: 'date',
                label: 'Data podpisania Umowy',
              },
              {
                key: 'status_symbol',
                label: 'Status',
              },
              {
                key: 'main_item_name',
                label: 'Produkt Główny',
              },
              {
                key: 'show_details',
                label: '',
              },
            ]}
          />
        </CCol>
      </CRow>
    </DataCard>
  );
};

export default AgreementsBox;
