import { CProgress } from '@coreui/react';
import React from 'react';
import classes from '../Application.module.css';

const ProgressValue = ({
  isPercentage = false,
  showLabel = true,
  color = 'success',
  value,
  max,
}) => {
  if (!value)
    return (
      <div className="flex-fill text-right d-flex justify-content-end pl-2 align-items-center">
        -
      </div>
    );
  return (
    <div className="d-flex align-items-center justify-content-end">
      <div className={`mx-2`}>
        <CProgress
          size="xs"
          className={classes.progressValue}
          value={value}
          max={max}
          color={color}
        />
      </div>
      {showLabel && (
        <>
          {isPercentage && (
            <div className={classes.progressValueLabel}>{`${value}%`}</div>
          )}
          {!isPercentage && (
            <div
              className={classes.progressValueLabel}
            >{`${value}/${max}`}</div>
          )}
        </>
      )}
    </div>
  );
};

export default ProgressValue;
