import { CCollapse, CDataTable } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import cs from 'classnames';
// eslint-disable-next-line
const styles = React.lazy(() => import('./Table.module.scss'));

const Td = (props) => <td className={styles.td} {...props} />;

const Table = function ({
  data,
  fields,
  DetailsComponent = null,
  TableTitle = null,
  scopedSlots = {},
  error = false,
  rowClicked = null,
  noBorder,
  labelExpand = 'Rozwiń',
  labelHide = 'Ukryj',
  ...props
}) {
  const [details, setDetails] = useState([]);
  const [clicked, setClicked] = useState(null);
  const history = useHistory();
  const toggleDetails = (index) => {
    const position = details.indexOf(index);
    let newDetails = details.slice();
    if (position !== -1) {
      newDetails.splice(position, 1);
    } else {
      newDetails = [...details, index];
    }
    setDetails(newDetails);
  };

  const getStatus = () => {
    if (props.loading) {
      return '';
    }
    if (error) {
      return 'Bład podczas pobierania danych';
    }
    return 'Brak danych';
  };
  const _scopedSlots = fields.reduce((a, b) => {
    if (a[b.key]) {
      return a;
    }
    return {
      ...a,
      [b.key]: (val) => <td>{val[b.key] ? val[b.key] : '-'}</td>,
    };
  }, scopedSlots);

  useEffect(() => {
    if (details.length > 0) {
      setDetails([]);
    }
    // eslint-disable-next-line
  }, [data]);
  const handleClick = (data) => {
    if (rowClicked && clicked && clicked?.time) {
      if (Date.now() - clicked.time < 500) {
        history.push(rowClicked(data));
      }
    }
    setClicked({ data, time: Date.now() });
  };
  return (
    <CDataTable
      items={data}
      fields={fields}
      // itemsPerPage={1}
      hover
      clickableRows={rowClicked ? true : null}
      onRowClick={rowClicked ? handleClick : null}
      responsive
      overTableSlot={TableTitle ? TableTitle : null}
      loadingSlot={
        <span></span>
        // <div
        //   className={
        //     "position-absolute d-flex align-items-center justify-content-center " +
        //     styles.loader
        //   }
        // >
        //   <CSpinner color="primary" style={{ width: "4rem", height: "4rem" }} />
        // </div>
      }
      scopedSlots={{
        // ...scopedSlots,
        ..._scopedSlots,
        ...(DetailsComponent
          ? {
              show_details: (item, index) => {
                return (
                  <Td className="py-2">
                    <Button
                      onClick={() => {
                        toggleDetails(index);
                      }}
                    >
                      {details.includes(index) ? labelHide : labelExpand}
                    </Button>
                  </Td>
                );
              },
              details: (item, index) => {
                return (
                  <CCollapse show={details.includes(index)}>
                    {DetailsComponent(item, details.includes(index))}
                  </CCollapse>
                );
              },
            }
          : {}),
      }}
      addTableClasses={cs('custom-table', !props.sorter && styles.noSorter)}
      noItemsViewSlot={
        <div className="table-no-data text-center">{getStatus()}</div>
      }
      {...props}
    />
  );
};

export default Table;
